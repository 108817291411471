<template>
  <div class="liff-reservation-schedule">
    <h4 class="text-center my-2" v-if="branchInfo || staffInfo">{{ branchInfo.name }} {{ staffInfo.name }}</h4>
    <h4 class="text-center my-2" v-if="serviceInfo && serviceInfo.name">{{ service.name }}</h4>
    <div class="s-mb-3">請選擇您要預約的日期</div>
    <div class="liff-reservation-schedule__date s-mb-4">
      <div v-if="isCalendarLoading" class="liff-reservation-schedule__loading-overlay">
        <div class="liff-reservation-schedule__loading-spinner"></div>
        <div class="liff-reservation-schedule__loading-text">載入中...</div>
      </div>
      <Datepicker
        placeholder="請輸入查詢開始時間"
        format="yyyy/MM/dd"
        v-model="date"
        :language="zh"
        :disabled-dates="disabledDates"
        calendar-class="datepicker-calendar"
        wrapper-class="datepicker-wrapper"
        bootstrap-styling
        inline
        @opened="handleCalendarOpened"
        @changedMonth="handleMonthChange"
      >
      </Datepicker>
    </div>

    <div class="s-mb-3">請選擇預約時間</div>
    <div class="liff-reservation-schedule__timer">
      <div
        v-for="timeOption in timeOptions"
        :key="timeOption"
        class="liff-reservation-schedule__time-option"
        :class="{
          'selected': time === timeOption,
          'disabled': !isTimeAvailable(timeOption)
        }"
        @click="selectTime(timeOption)"
      >
        <input
          type="radio"
          :id="timeOption"
          :value="timeOption"
          v-model="time"
          class="liff-reservation-schedule__time-input"
          :disabled="!isTimeAvailable(timeOption)"
        />
        <label :for="timeOption">{{ timeOption }}</label>
      </div>
    </div>

    <div class="liff-reservation-schedule__notice">
      <div class="liff-reservation-schedule__notice-divider"></div>
      <div class="s-mb-3 s-font-medium s-pt-3">注意事項</div>
      <div class="s-text-sm s-text-gray">週一為固定店休日，望周知。如果注意事項比較常會折行注意事項文字。</div>
    </div>

    <div class="liff-reservation-schedule__confirm sticky-bottom">
      <div v-if="date && time" class="s-flex s-justify-between s-items-center s-mb-3">
        <div class="s-font-medium">
          <p>預約時間：<span class="s-text-primary">{{ selectedDateTime }}</span></p>
          <div v-if="selectedEndDateTime">預計結束時間：<span class="s-text-primary">{{ selectedEndDateTime }}</span></div>
        </div>
        <div class="s-text-primary cursor-pointer s-font-medium s-flex-shrink-0" @click="resetDate()">重選</div>
      </div>
      <div class="s-flex s-justify-between s-items-center" style="gap: 8px;">
        <button
          class="s-btn s-liff-theme-btn-outline s-btn-sm"
          type="button"
          @click="prevStep"
          v-if="checkHasPrevStep('LiffReservationSchedule')"
        >
          上一步
        </button>
        <button
          class="s-btn s-liff-theme-btn s-btn-sm"
          type="button"
          :disabled="!date || !time"
          @click="nextStep">
          下一步
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { zh } from "vuejs-datepicker/dist/locale";
import {
  format,
  subDays,
} from "date-fns";
import reservationMixin from "@/mixins/liff/reservation";
import moment from 'moment';
import _ from "lodash";

export default {
  mixins: [reservationMixin],
  components: {
    Datepicker
  },
  data() {
    return {
      zh,
      date: null,
      time: null,
      disabledDates: {
        to: subDays(new Date(), 1),
        customPredictor: this.isDateDisabled,
        from: new Date(2100, 0, 1)
      },
      staffInfo: {},
      branchInfo: {},
      serviceInfo: {},
      timeSetting: {
        slot_minutes: 30,
        start_time: "12:00",
        end_time: "18:00",
        last_serve_time: "18:00"
      },
      enableStaffShift: false,
      staffShifts: [],
      timeOptions: [],
      unavailableTimeOptions: new Set(),
      currentDisplayMonth: moment().format('YYYY-MM'),
      isCalendarLoading: true,
      availableDates: [],
    }
  },
  computed: {
    selectedDateTime() {
      if (!this.date || !this.time) return '';
      return `${format(new Date(this.date), 'yyyy/MM/dd')} ${this.time}`;
    },
    selectedEndDateTime() {
      if (!this.selectedDateTime) return null;
      const durationTime = this.serviceInfo?.duration ?? 0;
      if (durationTime) {
        return moment(this.selectedDateTime).add(durationTime, 'minutes').format('YYYY/MM/DD HH:mm');
      } else {
        return null;
      }
    },
    branchId() {
      return this.$route.query.branch_id;
    },
    staffId() {
      return this.$route.query.staff_id;
    },
    moment() {
      return moment;
    },
  },

  async mounted() {
    this.fetching = true;
    if (this.branchId) {
      this.branchInfo = await this.get('branch', this.branchId);
    }
    if (this.staffId) {
      this.staffInfo = await this.get('staff', this.staffId);
    }
    this.serviceInfo = await this.get('service');
    await this.formatPresetTimeData();

    await this.initializeCalendar();

    this.fetching = false;
  },

  watch: {
    date() {
      this.time = null;
      if (this.date) {
        this.updateUnavailableTimeOptions();
      }
    }
  },

  methods: {
    async formatPresetTimeData() {
      const data = await this.fetchPresetData(this.$route.params.bookingPresetId, this.branchId);
      const startFieldId = _.get(data, 'config.booking_mapping.bookings.start_at', null);
      const defaultTimeField = _.get(data, 'config.fields', []).find((field) => field._id === startFieldId);
      const branchTimeField = _.get(data, 'branch_config.fields', [defaultTimeField]).find((field) => field._id === startFieldId);
      this.enableStaffShift = _.get(data, 'config.enabled_selected_staff_shift', false);

      this.timeSetting = branchTimeField ?? defaultTimeField;
      this.generateTimeOptions();
    },

    async initializeCalendar() {
      this.isCalendarLoading = true;

      const currentMonth = moment();

      if (this.enableStaffShift && this.staffId) {
        await this.fetchMonthlyShifts(currentMonth.toDate());
      }

      await this.computeAvailableDates();

      this.updateDisabledDates();

      if (this.availableDates.length > 0) {
        const sortedDates = [...this.availableDates].sort((a, b) => moment(a).diff(moment(b)));
        this.date = new Date(sortedDates[0]);
        this.updateUnavailableTimeOptions();
      }

      this.isCalendarLoading = false;
    },

    async computeAvailableDates() {
      this.availableDates = [];

      if (!this.enableStaffShift || !this.staffId) {
        // If staff shifts are not enabled, all dates are available (except past dates)
        const startDate = new Date();
        const endDate = moment().add(2, 'months').endOf('month').toDate();
        for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
          this.availableDates.push(new Date(d));
        }
        return;
      }

      const startOfMonth = moment().startOf('month');
      const endOfNextMonth = moment().add(2, 'months').endOf('month');

      for (let day = moment(startOfMonth); day.isBefore(endOfNextMonth); day.add(1, 'day')) {
        const currentDate = day.toDate();

        if (moment(currentDate).isBefore(moment().startOf('day'))) {
          continue;
        }

        if (!this.isDateDisabled(currentDate)) {
          this.availableDates.push(new Date(currentDate));
        }
      }
    },

    updateDisabledDates() {
      if (this.availableDates.length === 0) {
        // If no available dates, disable all future dates
        this.disabledDates = {
          to: subDays(new Date(), 1),
          from: new Date(2100, 0, 1)
        };
      } else {
        // Only enable the specific available dates
        this.disabledDates = {
          to: subDays(new Date(), 1),
          customPredictor: (date) => {
            // Check if this date is in the available dates list
            return !this.availableDates.some(availableDate =>
              moment(availableDate).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')
            );
          }
        };
      }
    },

    generateTimeOptions() {
      let options = [];
      const startTime = this.timeSetting.start_time;
      const endTime = this.timeSetting.end_time;
      if (!startTime || !endTime) {
        return [];
      }

      const slotMinutes = Number(this.timeSetting.slot_minutes);
      if (slotMinutes < 1) {
        return [];
      }

      const lastServeTime = this.timeSetting?.last_serve_time ?? endTime;
      const durationTime = isNaN(this.serviceInfo?.duration ?? 0) ? 0 : this.serviceInfo?.duration;

      // 將時間轉換為分鐘
      const timeToMinutes = (time) => {
        if (!time) {
          return 0;
        }
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
      };

      // 將分鐘轉回時間格式 "HH:MM"
      const minutesToTime = (minutes) => {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
      };

      const startMinutes = timeToMinutes(startTime);
      const endMinutes = timeToMinutes(endTime);
      const lastServeMinutes = timeToMinutes(lastServeTime);

      // 確保最後服務時間不超過 endMinutes
      const actualEndMinutes = Math.min(endMinutes, lastServeMinutes);

      let currentMinutes = startMinutes;

      // 計算每個時間選項
      while (currentMinutes + durationTime <= actualEndMinutes) {
        options.push(minutesToTime(currentMinutes));
        currentMinutes += slotMinutes;

        // 防止無窮迴圈：超過一天的範圍（1440 分鐘）
        if (currentMinutes >= 1440) {
          break;
        }
      }

      this.timeOptions = options;
      this.unavailableTimeOptions = new Set();
    },

    isDateDisabled(date) {
      const formattedDate = moment(date).format('YYYY-MM-DD');

      return this.staffShifts.some(shift => {
        if (!shift.start_at || !shift.end_at) return false;

        const shiftStartDate = moment(shift.start_at).format('YYYY-MM-DD');
        const shiftEndDate = moment(shift.end_at).format('YYYY-MM-DD');
        const shiftStartTime = moment(shift.start_at).format('HH:mm:ss');
        const shiftEndTime = moment(shift.end_at).format('HH:mm:ss');

        if (formattedDate >= shiftStartDate && formattedDate <= shiftEndDate) {
          if (formattedDate > shiftStartDate && formattedDate < shiftEndDate) {
            return true;
          }

          if (formattedDate === shiftStartDate && formattedDate === shiftEndDate) {
            return shiftStartTime === '00:00:00' && shiftEndTime === '23:59:59';
          }

          if (formattedDate === shiftStartDate) {
            return shiftStartTime === '00:00:00';
          }

          if (formattedDate === shiftEndDate) {
            return shiftEndTime === '23:59:59';
          }
        }

        return false;
      });
    },

    updateUnavailableTimeOptions() {
      if (!this.date) return;

      const selectedDate = moment(this.date).format('YYYY-MM-DD');
      this.unavailableTimeOptions = new Set(); // Reset the set

      const timeToMinutes = (time) => {
        if (!time) return 0;
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
      };

      const isTimeInRange = (timeStr, startTimeStr, endTimeStr) => {
        const time = timeToMinutes(timeStr);
        const startTime = timeToMinutes(startTimeStr);
        const endTime = timeToMinutes(endTimeStr);
        return time >= startTime && time <= endTime;
      };

      this.staffShifts.forEach(shift => {
        if (!shift.start_at || !shift.end_at) return;

        const shiftStartDate = moment(shift.start_at).format('YYYY-MM-DD');
        const shiftEndDate = moment(shift.end_at).format('YYYY-MM-DD');

        if (selectedDate >= shiftStartDate && selectedDate <= shiftEndDate && !this.isDateDisabled(this.date)) {
          const shiftStartTime = moment(shift.start_at).format('HH:mm');
          const shiftEndTime = moment(shift.end_at).format('HH:mm');

          if (selectedDate === shiftStartDate && selectedDate === shiftEndDate) {
            this.timeOptions.forEach(timeOption => {
              if (isTimeInRange(timeOption, shiftStartTime, shiftEndTime)) {
                this.unavailableTimeOptions.add(timeOption);
              }
            });
          }
          else if (selectedDate === shiftStartDate) {
            this.timeOptions.forEach(timeOption => {
              if (timeToMinutes(timeOption) >= timeToMinutes(shiftStartTime)) {
                this.unavailableTimeOptions.add(timeOption);
              }
            });
          }
          else if (selectedDate === shiftEndDate) {
            this.timeOptions.forEach(timeOption => {
              if (timeToMinutes(timeOption) <= timeToMinutes(shiftEndTime)) {
                this.unavailableTimeOptions.add(timeOption);
              }
            });
          }
        }
      });
    },

    isTimeAvailable(timeOption) {
      return !this.unavailableTimeOptions.has(timeOption);
    },

    selectTime(timeOption) {
      if (this.isTimeAvailable(timeOption)) {
        this.time = timeOption;
      }
    },

    handleCalendarOpened() {
      this.currentDisplayMonth = moment(this.date || new Date()).format('YYYY-MM');
    },

    handleMonthChange(date) {
      const newMonth = moment(date).format('YYYY-MM');
      if (this.currentDisplayMonth !== newMonth) {
        this.currentDisplayMonth = newMonth;
        this.fetchMonthlyShifts(date);
      }
    },

    async fetchMonthlyShifts(date) {
      this.isCalendarLoading = true;

      const startOfMonth = moment(date).startOf('month').format('YYYY-MM-DD');
      const endOfMonth = moment(date).endOf('month').format('YYYY-MM-DD');

      if (this.enableStaffShift && this.staffId) {
        this.staffShifts = await this.fetchStaffShift({
          booking_preset_id: this.$route.params.bookingPresetId,
          staff_id: this.staffId,
          start_at: startOfMonth,
          end_at: endOfMonth
        });

        // Recompute available dates
        await this.computeAvailableDates();
        this.updateDisabledDates();

        if (this.date) {
          this.updateUnavailableTimeOptions();
        }
      }

      this.isCalendarLoading = false;
    },

    resetDate() {
      // Find first available date
      if (this.availableDates.length > 0) {
        const sortedDates = [...this.availableDates].sort((a, b) => moment(a).diff(moment(b)));
        this.date = new Date(sortedDates[0]);
      } else {
        this.date = null;
      }

      this.time = null;
      if (this.date) {
        this.updateUnavailableTimeOptions();
      }
    },

    prevStep() {
      this.prevStepByList("LiffReservationSchedule", {
        branch_id: this.branchId,
        staff_id: this.staffId
      });
    },

    nextStep() {
      this.set('schedule', {
        start_at: this.selectedDateTime,
        end_at: this.selectedEndDateTime
      });
      this.nextStepByList("LiffReservationSchedule", {
        branch_id: this.branchId,
        staff_id: this.staffId
      });
    }
  }
}
</script>


<style lang="scss" scoped>
.liff-reservation-schedule {
  padding-bottom: 150px;

  &__loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
    border-radius: 10px;
  }

  &__loading-spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid var(--s-primary);
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 10px;
  }

  &__loading-text {
    font-size: 14px;
    color: var(--s-primary);
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  &__date {
    position: relative;
  }

  ::v-deep .vdp-datepicker__calendar {
    border: 1px solid #E5E5EA;
    border-radius: 10px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
    padding: 10px;

    .cell {
      position: relative;
      &.today:not(.selected) {
        background: transparent;
        color: var(--s-primary);
        border: 1px solid var(--s-primary);
        border: none;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 37px;
          height: 37px;
          background: var(--s-primary);
          opacity: 0.3;
          border-radius: 50%;
        }

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 37px;
          height: 37px;
          border: 1px solid var(--s-primary);
          border-radius: 50%;
        }
      }

      &:not(.blank):not(.disabled).day:hover,
      &.selected,
      &.selected:hover {
        background: transparent;
        color: #fff;
        border: none;
        z-index: 0;

        &::before {
          content: '';
          position: absolute;
          background: var(--s-primary);
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 37px;
          height: 37px;
          border: 1px solid var(--s-primary);
          border-radius: 50%;
          z-index: -1;
          box-shadow: 0 4px 4px 0 rgba(44, 44, 46, 0.15);
        }
      }
    }
  }

  &__notice {
    margin-top: 16px;

    &-divider {
      background: #E5E5EA;
      width: 100%;
      height: 1px;
      left: 0;
      position: absolute;
    }
  }

  &__timer {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  &__time-input {
    display: none;
  }

  &__time-option {
    border: 1px solid #E5E5EA;
    width: calc((100% - 16px) / 3);
    text-align: center;
    font-weight: 500;
    padding: 0 30px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
    height: 36px;
    line-height: 32px;
    &.selected {
      border: 3px solid var(--s-primary);
    }
    &.disabled {
      opacity: 0.5;
      background-color: #f5f5f5;
      cursor: not-allowed;
      border: 1px solid #E5E5EA;
    }
  }

  &__confirm {
    position: fixed;
    max-width: 768px;
    margin: auto;
    background-color: #fff;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    padding: 12px;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
    font-size: 13px;
  }
}

</style>
